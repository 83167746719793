import Navbar from 'react-bootstrap/NavBar';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  const [show, setShow] = useState(false);
  
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    number: '',
    resume: '',
    resumeName: '',
    referer: '',
    licensed: '',
  };
  const [eachEntry, setEachEntry] = useState(initialState);
  // const {
  //   firstName,
  //   lastName,
  //   email,
  //   number,
  //   resumeName,
  //   resume,
  //   referer,
  //   licensed,
  // } = eachEntry;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOnChange = (e) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  const onFileChange = async (e) => {
    // Update the state
    let file = e.target.files[0];
    // let filename = '' + file.name;
    let fr = new FileReader();
    fr.onloadend = ResumeLoaded;
    fr.readAsDataURL(file);
    // console.log(filename);
    // resumeName = filename
  };

  const ResumeLoaded = (fr) => {
    const resumeBase64 = fr.target.result;
    setEachEntry({ ...eachEntry, resume: resumeBase64 });
  };
  const handleResumeSubmit = (e) => {
    e.preventDefault();
    console.log(eachEntry);
    eachEntry.resumeName = document.getElementById('Resume').files[0].name;
    fetch('http://localhost:5000/sendResume', {
      method: 'POST',
      mode: 'no-cors',
      'Access-Control-Allow-Origin': '*',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eachEntry)
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          alert('Message Sent.');
          console.log('message sent');
          // setEachEntry({ ...eachEntry, resume: '' }) //reset form
          handleClose();
        } else if (data.status === 'fail') {
          alert('Message failed to send.');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="header">
      <Navbar collapseOnSelect bg="light" expand="lg" className="navBar">
        <Link to={`/`}>
          <img
            src="/Hypoteclogo.png"
            alt="Hypotec a new way of lending"
            className="logo"
          />
        </Link>
        <div className="navBarBtns">
          <Button onClick={handleShow} className="Btn">
            Join our team
          </Button>
          <Modal show={show} onHide={handleClose} className="resumeModal">
            <Modal.Header
              closeButton
              className="modal-title"
              style={{ backgroundColor: '#0071e3' }}
            >
              <Modal.Title> Application Form </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <Form style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    onChange={handleOnChange}
                    name="firstName"
                    type="text"
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    onChange={handleOnChange}
                    name="lastName"
                    type="text"
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>Primary Phone</Form.Label>
                  <Form.Control
                    onChange={handleOnChange}
                    name="number"
                    type="num"
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    onChange={handleOnChange}
                    name="email"
                    type="email"
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>
                    Were you referred by a Loan Officer at our company? If so,
                    please provide their name
                  </Form.Label>
                  <Form.Control
                    onChange={handleOnChange}
                    name="referer"
                    id="referer"
                    type="text"
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>What state(s) are you licensed in?</Form.Label>
                  <Form.Control
                    onChange={handleOnChange}
                    name="licensed"
                    id="licensed"
                    type="text"
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>Upload Resume</Form.Label>
                  <Form.Control
                    name="resumeName"
                    type="file"
                    id="Resume"
                    onChange={onFileChange}
                  />
                </Form.Group>
                <Button
                  style={{ margin: '10px' }}
                  variant="primary"
                  type="submit"
                  onClick={handleResumeSubmit}
                >
                  Submit
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </Navbar>
    </div>
  );
}
