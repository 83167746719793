import Header from './Header'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Home from './Home'
import { Routes, Route } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes>
    </div>
  )
}

export default App
