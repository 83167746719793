import Button from 'react-bootstrap/Button'

export default function Home() {
  return (
    <div className="homePage">
      {/* <Image src="/dotsleft.png" alt="dots" className="leftDots d-none-xs" />
      <Image src="/dotsright.png" alt="dots2" className="rightDots d-none-xs" /> */}
      <div className="headers">
        <h3>
          Check out why people are choosing Hypotec <br /> to take their career
          to the next level.
        </h3>
        <h4>Support, benefits, resources and more. </h4>
      </div>
      <div style={{ textAlign: 'center' }}>
        <video
          controls
          poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FHypotecVideo.png?alt=media&token=20b09208-556c-4819-bd98-0160b0467de9"
          width="600"
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FWhy%20Hypotec%20-%20Loan%20Officer%20Recruitment%20-%20Final%20Shorter.mp4?alt=media&token=b3250d00-4f63-428d-8f0b-c9309827ca06"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="hrForm">
        <p style={{ padding: '25px', textAlign: 'center' }}>
          Want to learn more? Join us on our weekly discovery call! <br /> Discover
          Hypotec is held with our CEO, Freddy Abitbol, every Tuesday from
          12:15PM EST – 1:00PM EST
        </p>
        <div style={{ textAlign: 'center' }}>
        <Button
            href="https://us02web.zoom.us/meeting/register/tZ0kcOutrD8tG93ZISJF5rlmTX9HEYBHB14Y"
            className="Btn"
            target="_blank"
          >
            Register Today
          </Button>
        </div>
      </div>
    </div>
  );
}
